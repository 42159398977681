import React from 'react';
import styled from 'styled-components';
import DottedPattern, { DottedPatternProps } from './DottedPattern';
import Title, { TitleProps } from './Title';

interface SectionProps {
    children?: any;
    id?: string;
    title?: TitleProps;
    hideBottomMargin?: boolean;
    maxWidth?: string;
    height?: number;
}

const SectionWrapperContainer = styled.div`
    position: relative;
    width: 100%;
    flex-direction: column;
`;

const SectionContainer = styled.div<{ hideBottomMargin?: boolean; maxWidth?: string; height?: number }>`
    position: relative;
    max-width: ${props => (props.maxWidth ? props.maxWidth : '1100px')};
    margin: 0 auto;
    margin-bottom: ${props => (props.hideBottomMargin ? '' : '10rem')};
    padding: 1.5rem;
    height: ${props => (props.height ? props.height + 'vh' : undefined)};
`;

export const SectionWrapper = ({ children, dottedPattern }: { children?: any; dottedPattern?: DottedPatternProps }) => {
    return (
        <SectionWrapperContainer>
            <DottedPattern {...dottedPattern} />
            {children}
        </SectionWrapperContainer>
    );
};

export const Section = (sectionProps: SectionProps) => {
    return (
        <SectionContainer
            id={sectionProps.id}
            hideBottomMargin={sectionProps.hideBottomMargin}
            maxWidth={sectionProps.maxWidth}
            height={sectionProps.height}
        >
            {sectionProps.title?.value && (
                <Title
                    value={sectionProps.title?.value}
                    subTitle={sectionProps.title?.subTitle}
                    subTitleFontStyle="secondary"
                />
            )}
            {sectionProps.children}
        </SectionContainer>
    );
};

export default Section;
