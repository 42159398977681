import React from 'react';
import styled from 'styled-components';
import Button from './Button';
import Title from './Title';

const Form = styled.form`
    -webkit-box-shadow: 0px 2px 5px 0px rgba(72, 117, 178, 0.15);
    -moz-box-shadow: 0px 2px 5px 0px rgba(72, 117, 178, 0.15);
    box-shadow: 0px 2px 5px 0px rgba(72, 117, 178, 0.15);
    background-color: var(--bgSecondary);
    color: var(--textNormal);
    border: var(--bgSecondary);
    padding: 3rem;
    border-radius: 6px;
`;

const FormInputWrapper = styled.div`
    margin-top: 1.5rem;
    display: flex;
`;

const FormInput = styled.input`
    width: 100%;
    flex-grow: 1;
    margin-right: 0.5rem;
    border-radius: 4px;
    padding: 0.5rem;
    border: 1px solid gray;
`;

export const Newsletter = () => {
    return (
        <Form
            action="https://tinyletter.com/rishavanand"
            method="post"
            target="popupwindow"
            onSubmit={() => {
                window.open('https://tinyletter.com/rishavanand', 'popupwindow', 'scrollbars=yes,width=800,height=600');
                return true;
            }}
        >
            <Title
                accent={false}
                size="h3"
                alignment="left"
                value="Subscribe to the newsletter"
                subTitle={`My goal with this blog is to let people know that full-time freelancing is a possibility and can also be more rewarding than steady corporate jobs if it is done correctly. I also intend to share my experiences, tips, and ideas with those around me. We rise by lifting others. My newsletters are going to revolve around the same idea. No spam, unsubscribe anytime.`}
                subTitleFontStyle="secondary"
                marginTop={0}
            />
            <FormInputWrapper>
                <FormInput
                    type="text"
                    style={{ width: '140px' }}
                    name="email"
                    id="tlemail"
                    placeholder="Enter your email"
                />
                <input type="hidden" value="1" name="embed" />
                <Button type="submit">Subscribe</Button>
            </FormInputWrapper>
        </Form>
    );
};

export default Newsletter;
