import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import styled from 'styled-components';
import { BlogPageLayout } from './Layout';
import Newsletter from './Newsletter';
import Section from './Section';
import Title from './Title';

const Image = styled(GatsbyImage)`
    border-radius: 6px;
    margin-bottom: 1.5rem;
`;

const PostHero = styled.div`
    padding: 6rem 0;
    text-align: center;
`;

const PostCategory = styled.div`
    color: #0652dd;
    font-weight: bold;
`;

const BlogContent = styled.div`
    margin-bottom: 1.5em;
`;

export const BlogPost = ({ data, pageContext }: { data: any; pageContext: any }) => {
    const { frontmatter, body } = data.mdx;
    const image = getImage(frontmatter.cover);
    // const { previous, next } = pageContext;
    const { category } = pageContext;
    return (
        <BlogPageLayout>
            <Section hideBottomMargin={true} maxWidth="675px">
                <PostHero>
                    <PostCategory>{frontmatter.category}</PostCategory>
                    <Title value={frontmatter.title} marginBottom={0} marginTop={0} accent={false} />
                </PostHero>
                {image && <Image image={image} alt={frontmatter.title} />}
                <BlogContent>
                    <MDXRenderer>{body}</MDXRenderer>
                </BlogContent>
                {category !== 'project' && <Newsletter />}
            </Section>
        </BlogPageLayout>
    );
};

export const query = graphql`
    query PostsBySlug($slug: String!) {
        mdx(fields: { slug: { eq: $slug } }) {
            body
            frontmatter {
                title
                category
                cover {
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                    }
                }
            }
        }
    }
`;

export default BlogPost;
