import React from 'react';
import styled from 'styled-components';

export type DottedPatternPosition = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

export interface DottedPatternProps {
    height?: number;
    width?: number;
    left?: number;
    right?: number;
    top?: number;
    bottom?: number;
}

const DottedPatterWrapper = styled.div<DottedPatternProps>`
    position: absolute;
    background-image: url(https://assets.website-files.com/5d419732b8b3b47636fc4c66/5d41adf5f65670135f80e148_Pattern%20Dot.svg);
    background-position: 0px 0px;
    background-size: 20px;
    opacity: 0.3;
    height: ${props => props.height + 'vh'};
    width: ${props => props.width + 'vw'};
    left: ${props => (props.left || props.left === 0 ? props.left + 'vw' : undefined)};
    right: ${props => (props.right || props.right === 0 ? props.right + 'vw' : undefined)};
    top: ${props => (props.top || props.top === 0 ? props.top + 'vh' : undefined)};
    bottom: ${props => (props.bottom || props.bottom === 0 ? props.bottom + 'vh' : undefined)};
`;

export const DottedPattern = (props: DottedPatternProps) => {
    return <DottedPatterWrapper {...props}>&nbsp;</DottedPatterWrapper>;
};

export default DottedPattern;
